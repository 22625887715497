
import Navbar from './global/navbar';
import PageHeader from './global/page-header';
import Footer from './global/footer';
import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import { SectionMerlos } from './global/SectionMerlos';

const ReviewaPage = () => {
    const { rpdata } = useContext(GlobalDataContext);
    return <div>
        <Navbar />
        <PageHeader headertitle="Reviews" Subheader="Reviews" bgimg={rpdata?.gallery?.[3]} />
        <div className='Content-Reviews'>
            <h1 className="text-center pb-10">{rpdata?.labels?.general?.titleReviews}</h1>
            <div class="elfsight-app-b0109e7e-dae1-4f0b-8649-a25a4c71ffa2"></div>
        </div>
        <Footer />
    </div>
}

export default ReviewaPage
