import React, { Component, useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './components/home';
import About from './components/about';
import Services from './components/services';
import Gallery from './components/gallery';
import Contact from './components/contact';
import ReviewaPage from './components/reviews';
import { GlobalDataContext } from './context/context';
import axios from 'axios'
import { ServicesDetail } from "./components/section-components/ServicesDetail";
import { GalleryDetail } from "./components/section-components/GalleryDetail";


function Root() {


    const id = "62fea76223766e88e1faeb69"
  const [rpdata, setrpdata] = useState({});


  //useEffect para peticion al servidor
  useEffect(() => {
    // console.log(“haciendo fetch”)
    async function fetchData() {
    
      try {
        const response = await axios({
          baseURL: "https://servidorpublico.herokuapp.com/api",
          url: `/paginas/${id}`,
          method: "get"
        });
        console.log(response.data)
        //se convierte la data en el objeto
        setrpdata(JSON.parse(JSON.stringify(response.data)));
        // console.log(rpdata)
     
      } catch (error) {
        console.log(error);
     
      }
    };
    fetchData();
  }, [])


        return(
            <>
            <GlobalDataContext.Provider value={{ rpdata }}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />}></Route>
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/reviews" element={<ReviewaPage/>}></Route>
          <Route path="/contact" element={<Contact />} />
          <Route path="/services/:id" element={<ServicesDetail />} />
          <Route path="/gallery/:id" element={<GalleryDetail />} />
        </Routes>
      </Router>
      </GlobalDataContext.Provider>
                </>
        )
    }

export default Root;

ReactDOM.render(<Root />, document.getElementById('mingrand'));
